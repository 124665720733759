import React, { Component } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import "./App.css";
import Routes from "./routes";
import { blue, indigo } from "@material-ui/core/colors";
import { createStore } from "redux";
import { Provider } from "react-redux";
import SimpleStore from "./stores/simpleState";
import MetaPixel from "./utils/metaPixel";

const theme = createTheme({
  palette: {
    secondary: {
      main: blue[900],
    },
    primary: {
      main: "#f6bc50",
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Lato"', "sans-serif"].join(","),
  },
});

class App extends Component {
  render() {
    
    return (
      <Provider store={SimpleStore}>
        <MetaPixel />
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;

import React from "react";
import { Helmet } from "react-helmet";

const MetaPixel = () => {
var substore = window.location.hash.split('/')[1] == '' ||  window.location.hash.split('/')[1].includes("landing") ? 'garnetek' : window.location.hash.split('/')[1];
var fpixel = '';
// if(substore == 'bicisport'){
    fpixel = '<script>!function(e,t,n,a,c,o,s){e.fbq||(c=e.fbq=function(){c.callMethod?c.callMethod.apply(c,arguments):c.queue.push(arguments)},e._fbq||(e._fbq=c),c.push=c,c.loaded=!0,c.version="2.0",c.queue=[],(o=t.createElement(n)).async=!0,o.src=a,(s=t.getElementsByTagName(n)[0]).parentNode.insertBefore(o,s))}(window,document,"script","https://connect.facebook.net/en_US/fbevents.js"),fbq("init","796124383864117"),fbq("track","PageView");</script><noscript ><img height="1"width="1"style="display:none"src="https://www.facebook.com/tr?id=796124383864117&ev=PageView&noscript=1"/></noscript>;';
// }
if(substore == 'bicisport'){
  return (
    <Helmet>
     <script id="facebook-pixel-script">
      {
        `function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '796124383864117');
        fbq('track', 'PageView');`
      }</script>
      <noscript id="facebook-pixel-image">{`<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=796124383864117&ev=PageView&noscript=1"
        />`
        }</noscript>
    </Helmet>
  );
}
else{
    return ('');
}
};

export default MetaPixel;
import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Topbar from "../components/TopbarGarnet";
import FooterGarnet from "../components/FooterGarnet";
import Button from "@material-ui/core/Button";
import { ArrowBack, CloudDownload } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import API from "../api/axios";
import Zoom from "@material-ui/core/Zoom";
import regionesComunas from "../data-utils/regiones-comunas";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ReactDOM from "react-dom";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Pageview from '@material-ui/icons/Pageview';
import Delete from '@material-ui/icons/Delete';

import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Loading from "../components/common/Loading";

import FormData from 'form-data';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const fondoImagenUrl = require("../images/back-bike.jpg");
const iconoCentral = require("../images/icono_central.png");
const logoMercadopago = require("../images/mercadopago.svg");
const pdfCondicionesGarantiaExtendida = require("../pdfs/Certificado_de_Cobertura_Seguro.pdf");
const pdfCondicionesRobo = require("../pdfs/Certificado_de_Cobertura_Seguro.pdf");

const pdfCondicionesv1 = require("../pdfs/certificado_de_cobertura_seguro_bicicletas_v1.pdf");
const pdfCondicionesv2 = require("../pdfs/certificado_de_cobertura_seguro_bicicletas_v2.pdf");

const iconoFeatures1 = require("../images/icons/011-frame-3.png");
const iconoFeatures2 = require("../images/icons/009-cable.png");
const iconoFeatures3 = require("../images/icons/002-flying-money-1.png");
const iconoFeatures4 = require("../images/icons/010-shield-1.png");
const iconoFeaturesList = require("../images/icons/004-trusted.png");
const iconoFeaturesCondition = require("../images/icons/check.png");
const iconoFeaturesConditionShield = require("../images/icons/006-shield.png");


var _ = require("lodash");

const styles = (theme) => ({
  fondoImagen: {
    background: `url(${fondoImagenUrl}) no-repeat`,
    backgroundSize: "cover",
    height: "calc(100vh - 126px)",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      // display: "none",
      // height: "initial",
      height: "100vh",
      display: "block",
      position: "fixed",
      top: 0,
      width: "100%",
      backgroundPosition: "center",
    },
  },
  iconoCentral: {
    width: "200px",
    marginLeft: "-100px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tituloPrincipal: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "42px",
    lineHeight: "1.2",
    color: "#354851",
    marginBottom: "1rem",
  },
  labelSelect: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "118.3%",
    color: "#2D2D2D",
    marginBottom: "1rem",
  },
  tituloPlan: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "1",
    color: "#f6bc50",
  },
  textoPlan: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#474747",
    marginBottom: "1.5rem",
  },
  textoPlanDetalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#474747",
    marginBottom: "1.5rem",
  },
  textoPlan2Detalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#2D2D2D",
    marginBottom: "1.5rem",
  },
  precioPlan: {
    fontFamily: "Source Sans Pro",
    fontStyle: "bold",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "26px",
    color: "#354851",
    marginBottom: "1rem",
  },
  precioPlanRef: {
    fontFamily: "Source Sans Pro",
    fontStyle: "bold",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#354851",
    marginBottom: "1rem",
  },
  precioPlanDetalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "bold",
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "26px",
    color: "#354851",
    marginBottom: "2rem",
  },
  tarjetaPlan: {
    marginBottom: "1rem",
    padding: "10px",
    background: "#FFFFFF",
    border: "2px dashed transparent",
    boxSizing: "border-box",
    borderRadius: "5px",
    "&:lastChild": { border: "2px solid transparent" },
    "&:hover": {
      border: "2px solid #354851",
      background: "#354851",
      cursor: "pointer",
    },
    "&:hover > p": { color: "#FFFFFF !important" },
    width: "45%",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    textAlign: "center",
    float: "left",
  },
  containerTab: {
    padding: "10px 7rem 10px 5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 1rem 10px 1rem",
    },
  },
  columnaIzquierda: {
    height: "calc(100vh - 126px)",
    overflow: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      height: "initial",
      overflow: "initial",
      overflowX: "initial",
      backgroundColor: "rgba(255,255,255,1)",
      position: "relative",
      zIndex: "1",
      minHeight: "calc(100vh - 160px)",
    },
  },
  containerGrid: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
  imagesFormBlocks: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "5px",
    marginBottom: "1rem",
    border: "1px dashed #9e9e9e",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
      marginBottom: "0px",
    },
  },
  progessBarTop: {
    width: "calc(100% - 20px)",
    border: "1px solid #b0c5db",
    marginTop: "5px",
    height: "10px",
    borderRadius: "2px",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "10px",

  },
  activeStep: {
    background: "#b0c5db",
  },
  iconoFeatures: {
    textAlign: "center",
    marginBottom: "15px",
  },
  iconoFeaturesText: {
    padding: "0 1rem",
    lineHeight: "1.2",
    fontSize: "14px",
    
  },
  listFeatures: {
    // background: "#dfe6ed",
    padding: "5px 15px",
  },
  listFeaturesItemIcon: {
    width: "100%",
  },
  ListFeaturesText: {
    fontSize: "14px",
    paddingLeft: "10px",
    lineHeight: "1.1",
  },
  listFeaturesItem:{

  },
  subTituloPrincipalText:{
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "1",
    color: "#354851",
    marginBottom: "2rem",
    width: "calc(100% - 20px)",
    padding: "0 10px",
  },
  subTituloPrincipal:{
    color: "#354851",
    fontSize: "28px",
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    width: "calc(100% - 20px)",
    padding: "0 10px",
    marginBottom: "0px",
  },
  conditionFeaturesList:{
    background: "#dfe6ed",
    padding: "30px 0 20px 0",
    borderRadius: "30px 0 30px 0",
    marginBottom: "20px",
  },
  conditionFeatureIconShield:{
    width: "40px",
    marginLeft: "5px",
    marginBottom: "30px",
    opacity: "0.9",
  },
  conditionFeatures:{
    marginBottom: "20px",
    width: "calc(100% - 40px)",
    display: "inline-block",
    padding: "0px 20px 0px 20px",
  },
  conditionFeatureIcon:{
    width: "20px",
    float: "left",
    marginRight: "10px",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "25px",
    },
  },
  conditionFeatureText:{
    fontSize: "18px",
    display: "block",
    fontWeight: "bold",
    color: "#000",
  },
  iconDowloadDocument:{
    width: "35px",
    marginLeft: "15px",
  },
  checkoutStep:{
    width: "100%",
    background: "#dfe6ed",
    padding: "10px 0",
    marginBottom: "20px",
    
  },
  checkoutStepIcon:{
    width: "30px",
    marginLeft: "10px",
  },
  checkoutStepText:{
    fontSize: "20px",
    fontWeight: "bold",
  },
  homeBoxFeatures:{
    background:"#dfe6ed",
    padding: "10px 0px",
    margin:"0 auto 20px",
    borderRadius:"10px",
    maxWidth:"430px",
  },
  homeInputForm:{
    width: "400px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      minWidth: "360px",
      width: "auto",
    },
  },
  homeButtonForm:{
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    fontSize: "24px",
    textTransform: "capitalize",
    marginBottom: "2rem",
    alignSelf: "stretch",
    width: "400px",
    margin: "0 auto",
    padding: "1rem 0rem",
    [theme.breakpoints.down("sm")]: {
      minWidth: "360px",
      width: "auto",
    },
  }
});

const queryString = require("query-string");

class Landing1 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isLoading: false,
      loading: true,
      precio1: "",
      producto: "",
      precio: "",
      productosList: [],
      preciosList: [],
      planes: [],
      activeTab: 1,
      planesEncontrados: [],
      planSeleccionado: undefined,
      regionesList: [],
      comunasList: [],
      testField: "",
      "N° Guia de despacho": "",
      "N° de pedido": "",
      RUT: "",
      Nombres: "",
      "Apellido Paterno": "",
      "Apellido Materno": "",
      "Fecha de Nacimiento": "",
      Dirección: "",
      Región: "",
      Comuna: "",
      "Teléfono móvil/fijo": "",
      Email: "",
      condicion1: false,
      condicion2: false,
      condicion3: false,
      condicion4: false,
      condicion5: false,
      isFormValid: false,
      openSnackbar: false,
      mensajesError: [],
      showBotonPagarMercadopago: false,
      campos_formulario_venta: [],
      labelWidth: 0,
      open: false,
      setOpen: false,
      openPreviewImage: false,
      dialogPreviewImage : "",
      picture1: false,
      // picture2: false,
      // picture3: false,
      picture4: false,
      picture5: false,
      formInputError: false,
      biciTypes : [
        "Bicicleta urbana", "Bicicleta de ruta", "Bicicleta de montaña", "Bicicleta plegable", "Bicicleta BMX", "Bicicleta de paseo"
      ],
      user_param: null,
      landing: window.location.hash.split('/')[1],
      landing_url: window.location.href.replaceAll('/#/',''),
      currentPath: window.location.hash.split('/')[1],
      hoverCard : false,
      introFormValid : false,

    };
  }
  componentDidMount() {
    // this.setState({
    //   labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    // });
    var urlhash = window.location.hash;
    console.log(window.location.hash);
    urlhash = urlhash.replace('/?','');
    console.log(urlhash);
    var values = queryString.parse(urlhash);
    console.log(values);
    
    if (values.user) {
      this.setState({
        user_param: values.user,
      });
    }
    this.setState({
      regionesList: regionesComunas.regiones.map((e) => e.region),
    });
    let landing_param = "garnetekbici";
    if (this.state.landing != '' ) {
      landing_param = this.state.landing;
    }
    console.log("api/landing/bici/plans/" + landing_param );
    API.get("api/landing/bici/plans/" +  landing_param ).then((response) => {
      this.setState({ planes: response.data });
      const productosSet = new Set();
      response.data.forEach((e) => {
        productosSet.add(e.materia.descripcion);
      });
      const productosArray = [];
      productosSet.forEach((e) => {
        productosArray.push(e);
      });
      this.setState({ productosList: productosArray });
      this.setState({ loading: false });
    });
  }
  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleChangeProducto(event) {
    this.setState({
      producto: event.target.value,
      precio: "",
      preciosList: [],
    });
    const preciosSet = new Set();
    this.state.planes
      .filter(
        (e) =>
          e.materia.descripcion == event.target.value &&
          e.name.includes("desde") &&
          e.name.includes("hasta")
      )
      .map((e) => e.name.slice(e.name.indexOf("desde")))
      .forEach((e) => {
        preciosSet.add(e);
      });
    const preciosArray = [];
    preciosSet.forEach((e) => {
      preciosArray.push(e);
    });
    this.setState({ preciosList: preciosArray });
  }
  handleChangePrecio(event) {
    console.log({ precio: event.target.value });
    this.setState({ precio: event.target.value });
  }
  toFirstTab() {
    console.log("toFirstTab");
    this.setState({ activeTab: 1 });
  }
  toSecondTab() {
    console.log("toSecondTab");
    if (this.state.precio1) {
      this.setState(
        {
          planesEncontrados: this.state.planes
            .filter((e) => {
              if (!JSON.parse(e.filters)) return false;
              if (!JSON.parse(e.filters).campos_range.length) return false;
              return (
                
                parseFloat(this.state.precio1) >=
                  parseFloat(
                    JSON.parse(e.filters).campos_range[0].plan_desde
                  ) &&
                parseFloat(this.state.precio1) <=
                  parseFloat(JSON.parse(e.filters).campos_range[0].plan_hasta)
              );
            })
            .map((e) => ({
              ...e,
              isActive: !this.state.planSeleccionado
                ? false
                : this.state.planSeleccionado.id == e.id,
              pricePesos: e.pricePesos,
              dateUf: e.dateUf,
              valueUf: e.valueUf,
              priceUf: e.priceUf,
            })),
        },
        // Luego de esperar que actulize el state
        () => {
          if (this.state.planesEncontrados.length) {
            this.setState({ activeTab: 2 });
          } else {
            const mensajesError = [
              {
                active: true,
                message:
                  "No encontramos resultados. Intenta con otros valores.",
              },
            ];
            this.setState({ mensajesError });
          }
        }
      );
      this.sendIntroForm();
    }
  }
  seleccionarPlan(plan) {
    this.setState({
      planesEncontrados: this.state.planesEncontrados.map((e) => {
        e.isActive = plan.id == e.id;
        return e;
      }),
    });
    let atributosMateriaTotal = JSON.parse(plan.materia.atributos);
    console.log("atributosMateriaTotal", atributosMateriaTotal);
    let atributosMateria = [];
    // Campos provenientes de la materia
    atributosMateriaTotal.forEach((a) => {
      if (a.momento == "venta") {
        atributosMateria.push({
          name: _.capitalize(a.nombre),
          attr_type: "materia",
          data_type: "string",
        });
      }
    });
    // Campos provenientes de la subcategoria (para filtrar en el POS en vista de nueva venta)
    plan.plansubcategoria.searchablesattrs.forEach((sa) => {
      atributosMateria.push({
        name: _.capitalize(sa.public_name),
        attr_type: "subcategoria",
        data_type: sa.data_type_input,
      });
    });
    //console.log('atributosMateria', atributosMateria);
    let obj = new Object();
    atributosMateria.forEach((am) => {
      console.log(am);
      if (am.name.toLowerCase() == "el valor del equipo") {
        obj[am.name] = this.state.precio1;
      }else {
        obj[am.name] = "";
      }
    });

    //console.log('obj', obj);
    this.setState({ ...obj }, () => {
      this.setState({ campos_formulario_venta: atributosMateria });
      this.setState({ planSeleccionado: plan });
    });
    this.myRef.current.scrollIntoView({ behavior: "smooth" });
  }
  toThirdTab() {
    console.log("toThirdTab");
    if (this.state.planSeleccionado) {
      this.setState({ activeTab: 3 });
    }
  }
  toForthTab() {
    console.log("toThirdTab");
    if (this.state.planSeleccionado) {
      this.setState({ activeTab: 4 });
    }
  }
  handleChangeTextField(event, property) {
    let obj = new Object();
    obj[property] = event.target.value;
    this.setState({ ...obj });
    if (property) {
      console.log("valid");
      this.validationInput(event, property);
    }
    if (property == "Email") {
      if ( /\S+@\S+\.\S+/.test(event.target.value) ) {
        this.setState({ introFormValid: true });
      }
      else{
        this.setState({ introFormValid: false });
      }
    }
  }
  handleChangeCheckbox(event, property) {
    let obj = new Object();
    obj[property] = event.target.checked;
    this.setState({ ...obj });
  }
  handleChangeRegion(event) {
    this.setState({ Región: event.target.value, Comuna: "", comunasList: [] });
    this.setState({
      comunasList: regionesComunas.regiones.find(
        (e) => e.region == event.target.value
      ).comunas,
    });
  }
  handleChangeSelectField(event, property) {
    let obj = new Object();
    obj[property] = event.target.value;
    this.setState({ ...obj });
    // if (property) {
    //   console.log("valid");
    //   this.validationInput(event, property);
    // }
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log("onSubmit");
    this.setState({ isLoading: true }, () => this.sendForm());
  }
  validationInput(event, property){
    console.log("validationInput", property);
    console.log("this.state[event]", event.target.value);
    let formInputErrorSt = false;
    let formInputErrorMsg = false;
    switch(property) {
      case 'Fecha de compra':
        const pastTime = new Date(event.target.value);
        const now = new Date();
        console.log("pastTime", pastTime);
        console.log("now", now);
        const sixMontsInMs = 6 * 30 * 24 * 60 * 60 * 1000;
        const timeDiffInMs = now.getTime() - pastTime.getTime();

        if(timeDiffInMs >= sixMontsInMs){
          formInputErrorSt = true;
          formInputErrorMsg = "La fecha de compra debe ser menor a 6 meses";
          console.log("formInputError", formInputErrorSt);
          console.log("formInputErrorMsg", formInputErrorMsg);
        }
        break;
      case 'Fecha de Nacimiento':
          var optimizedBirthday = event.target.value.replace(/-/g, "/");
          console.log("optimizedBirthday", optimizedBirthday);
          var myBirthday = new Date(optimizedBirthday);
          console.log("myBirthday", myBirthday);
          var currentDate = new Date().toJSON().slice(0,10)+' - 03:00:00';
          console.log("currentDate", currentDate);
          var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));
          console.log("myAge", myAge);
          if(myAge < 18){
            formInputErrorSt = true;
            formInputErrorMsg = "El Seguro debe ser tomado por un mayor de edad";
            console.log("formInputError", formInputErrorSt);
            console.log("formInputErrorMsg", formInputErrorMsg);
          }
        break;
      default:
        break;
    }

    // this.setState(this.formValid, formInputError);
    this.setState({ formInputError: formInputErrorSt });
    

    if(formInputErrorSt){
      const mensajesError = [
        {
          active: true,
          message:formInputErrorMsg,
        },
      ];
      this.setState({ mensajesError }, () => {});
    }
    else{
      const mensajesError = [
        {
          active: false,
          message:'',
        },
      ];
      this.setState({ mensajesError }, () => {});
    }

  }
  sendForm() {
    this.setState({ loading: true });
    return new Promise((resolve, eject) => {
      const formData = _.pick(
        this.state,
        [
          "N° Guia de despacho",
          "N° de pedido",
          "RUT",
          "Nombres",
          "Apellido Paterno",
          "Apellido Materno",
          "Fecha de Nacimiento",
          "Dirección",
          "Región",
          "Comuna",
          "Teléfono móvil/fijo",
          "Email",
          "planSeleccionado",
          "picture1",
          "picture2",
          "picture3",
          "picture4",
          "picture5",
          "landing",
          "landing_url"
        ].concat(
          this.state.campos_formulario_venta.map((cf) => cf.name),
          ["campos_formulario_venta"]
        )
      );
      formData.user = this.state.user_param ? this.state.user_param : null;
      API({
        url: "api/bici/venta",
        method: "POST",
        data: formData,
      })
      .then(async (response) => {
        //console.log(response.data);
        //alert("Se redirigirá a: " + response.data);
        setTimeout(() => {
          window.location.href = response.data;
        }, 1500);

        /*
        this.props.dispatch({
          type: "SET_PREFERENCE_ID",
          value: 'response.data.preference_id', // OJO AQUI <<<<<<<<<<<
        });
        */
        this.setState({ isLoading: false }, () => {
          resolve();
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        this.setState({ isLoading: false });
        console.log("error.response.status", error.response.status);
        if (error.response.status == 422) {
          if (error.response.data) {
            if (error.response.data.errors) {
              if (Object.keys(error.response.data.errors).length) {
                const mensajesError = Object.keys(
                  error.response.data.errors
                ).map((key) => {
                  return {
                    active: true,
                    message: error.response.data.errors[key][0],
                  };
                });
                this.setState({ mensajesError }, () => {});
                eject(error);
                return;
              }
            }
          }
        }
        // const mensajesError = [
        //   {
        //     active: true,
        //     message:
        //       "Ha ocurrido un error en el servidor. Reintente mas tarde.",
        //   },
        // ];
        // this.setState({ mensajesError }, () => {});
        // eject(error);
      });

    });
  }
  sendIntroForm() {

    let landing_param = "garnetekbici";
    if (this.state.landing != '' ) {
      landing_param = this.state.landing;
    }
    var introForm = {
      "precio1": this.state.precio1,
    }
    API({
      url: "api/v2/introform",
      method: "POST",
      data: 
        {
          "landing": landing_param,
          "email": this.state["Email"],
          "info": JSON.stringify(introForm)
        }
      
    })
  }
  handleClickBotonPagarMercadopago() {
   
    this.setState({ isLoading: true }, () => {
      this.sendForm();
    });
  }
  handleCloseSnackbar(index) {
    let mensajesError = this.state.mensajesError.map((e, i) => {
      if (i == index) {
        return { ...e, active: false };
      } else {
        return e;
      }
    });
    this.setState({ mensajesError: mensajesError });
  }
  handleUploadImage(file, property) {
    // setData("file", e.target.files[0])
    this.setState({ loading: true });
    console.log(file);
    console.log(property);
   
    let formdata = new FormData();
    formdata.append("file",file);

    API({
      url: "api/bici/image",
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
      .then(async (response) => {
        console.log(response.data);

         let obj = new Object();
        obj[property] = response.data;
        this.setState({ ...obj });

        this.setState({ loading: false });

      })
      .catch((error) => {
        console.log(error);
        
      });

      
  }
  handleCloseDialogPreviewImage() {
    this.setState({ openPreviewImage: false });
  }
  handleopenDialogPreviewImage(imageToPreview) {
    console.log(imageToPreview);
    this.setState({ dialogPreviewImage: imageToPreview });
    this.setState({ openPreviewImage: true });
  }
  handleoRemoveImage(property) {
    let obj = new Object();
    obj[property] = false;
    this.setState({ ...obj });
  }
  currentMainColor(){
    if (this.state.currentPath == "cuq") {
      return "#15669D";
    }
    else if (this.state.currentPath == "h2o") {
      return "#f6bc50";
    }
    else if (this.state.currentPath == "bicisport") {
      return "#333333";  
    }
    else {
      return "#f6bc50";
    }
  }
  currentIconoCentral(){
    if (this.state.currentPath == "cuq") {
      return require("../images/icono_central_cuq.png");
      
    }
    else if (this.state.currentPath == "h2o") {
      return iconoCentral;
      
    }
    else if (this.state.currentPath == "bicisport") {
      return require("../images/icono_central_black.png");

    }
    else {
      return iconoCentral;
    }
  }
  handleMouseEnterCard = () => {
    this.setState({ hoverCard: true });
  }
  handleMouseLeaveCard = () => {
    this.setState({ hoverCard: false });
  }
  render() {
    const { classes } = this.props;
    const formValid =
      !this.state.isLoading &&
      !!this.state["RUT"] &&
      !!this.state["Nombres"] &&
      !!this.state["Apellido Paterno"] &&
      !!this.state["Apellido Materno"] &&
      !!this.state["Fecha de Nacimiento"] &&
      !!this.state["Dirección"] &&
      !!this.state["Región"] &&
      !!this.state["Comuna"] &&
      !!this.state["Teléfono móvil/fijo"] &&
      !!this.state["Email"] &&
      !!this.state.condicion1 &&
      // !!this.state.condicion2 &&
      // !!this.state.condicion3 &&
      // !!this.state.condicion4 &&
      // !!this.state.condicion5 &&
      // &&
      !!this.state.picture1 &&
      // !!this.state.picture2 &&
      // !!this.state.picture3 &&
      !!this.state.picture4 &&
      !!this.state.picture5
      this.state.campos_formulario_venta.every((e) => !!this.state[e.name]);
    let pdfTerminosCondiciones = "";
    if (this.state.planSeleccionado) {
      pdfTerminosCondiciones = this.state.planSeleccionado.name
        .toLowerCase()
        .includes("salud")
        ? pdfCondicionesv2
        : pdfCondicionesv1;
    } else {
      pdfTerminosCondiciones = "https://garnetek.xyz/";
    }
    return (
      <>
      <Loading loading={this.state.loading} />
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          
          <DialogTitle id="alert-dialog-slide-title">
            {"¿Dónde obtengo el N° de serie?"}
          </DialogTitle>
          <DialogTitle>
            <Typography variant="subtitle1" gutterBottom>
            El número de serie de una bicicleta se encuentra en diferentes lugares dependiendo del fabricante. A menudo se encuentra debajo de la tuerca de la horquilla o debajo de la base del pedalier. También se puede encontrar en el cuadro de la bicicleta, en la parte inferior de la tija de sillín o en el tubo de dirección. Es importante anotar este número ya que es una forma importante de identificar una bicicleta en caso de robo.
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <img
                style={{ width: "100%" }}
                src={require("../images/bike_series.jpg")}
              ></img>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openPreviewImage}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
 
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <img
                style={{ width: "100%" }}
                src={this.state.dialogPreviewImage}
              ></img>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleCloseDialogPreviewImage()} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        
        <Topbar currentPath = {this.state.currentPath} />
        <Grid className={classes.containerGrid} container spacing={0}>
          <Grid
            className={classes.columnaIzquierda}
            item
            xs={12}
            sm={12}
            md={6}
          >
            {this.state.activeTab === 1 && (
              <Zoom in={true} timeout={400}>
                <div className={classes.containerTab}>

                <Grid md={12} className={classes.progessBarTop} container>
                <Grid xs={6} sm={6} md={6} className={classes.activeStep} ></Grid>
                <Grid xs={6} sm={6} md={6} ></Grid>
                </Grid>
                
                  <Typography className={classes.tituloPrincipal} style={{textAlign: "center"}} >
                  Despreocúpate y disfruta tu bici

                  </Typography>



                  <Grid  container>
                    <Grid xs={6} sm={6} md={6} className={classes.iconoFeatures}> <img  src={iconoFeatures1} alt="icono"></img><Typography  className={classes.iconoFeaturesText}>Pérdida total por Daño</Typography></Grid>
                    <Grid xs={6} sm={6} md={6} className={classes.iconoFeatures}> <img  src={iconoFeatures2} alt="icono"></img><Typography className={classes.iconoFeaturesText}>Robo de tu bici</Typography></Grid>
                  </Grid>
                  <Grid  container>
                    <Grid xs={6} sm={6} md={6} className={classes.iconoFeatures}> <img  src={iconoFeatures3} alt="icono"></img><Typography  className={classes.iconoFeaturesText}>Gastos extra generados</Typography></Grid>
                    <Grid xs={6} sm={6} md={6} className={classes.iconoFeatures}> <img  src={iconoFeatures4} alt="icono"></img><Typography className={classes.iconoFeaturesText}>Protegido</Typography></Grid>
                  </Grid>
    
                  <div className={classes.homeBoxFeatures}>
                  <Typography className={classes.tituloPrincipal} style={{textAlign: "center", marginBottom:"10px",fontSize:"22px", lineHeight: "1", marginTop:"5px"}} >
                  ¿Por qué contratar Seguro de Bici?
                  </Typography>
                  <Grid  container className={classes.listFeatures}>
                    <Grid xs={1} sm={1} md={1} className={classes.listFeaturesItem}> <img  src={iconoFeaturesList} className={classes.listFeaturesItemIcon} alt="icono"></img></Grid>
                    <Grid xs={11} sm={11} md={11} className={classes.listFeaturesItem} ><Typography  className={classes.ListFeaturesText}>La bici es tu inversión y el riesgo de robo es evidente. Además, está protegida en caso de pérdida total por daño</Typography></Grid>
                  </Grid>
                  <Grid  container className={classes.listFeatures}>
                    <Grid xs={1} sm={1} md={1} className={classes.listFeaturesItem}> <img  src={iconoFeaturesList} className={classes.listFeaturesItemIcon} alt="icono"></img></Grid>
                    <Grid xs={9} sm={9} md={9} className={classes.listFeaturesItem} ><Typography  className={classes.ListFeaturesText}>Por una cuota mensual de bajo costo la puedes proteger</Typography></Grid>
                  </Grid>
                  <Grid  container className={classes.listFeatures}>
                    <Grid xs={1} sm={1} md={1} className={classes.listFeaturesItem}> <img  src={iconoFeaturesList} className={classes.listFeaturesItemIcon} alt="icono"></img></Grid>
                    <Grid xs={9} sm={9} md={9} className={classes.listFeaturesItem} ><Typography  className={classes.ListFeaturesText}>Además de coberturas de accidentes si las necesitas</Typography></Grid>
                  </Grid>
  
                </div>

                  <Typography className={classes.subTituloPrincipal} style={{textAlign:"center"}}>
                  Cuéntanos sobre tu bici
                  </Typography>
                  <Typography className={classes.subTituloPrincipalText} style={{textAlign:"center"}}>
                  Cotiza en menos de 3 minutos, estás en el paso 1 de 2

                  </Typography>

                  {/* <div style={{ marginBottom: "1rem", marginTop: "2rem" }}>
                    &nbsp;
                  </div> */}
                  {/* <Typography className={classes.tituloPrincipal}>
                  Obtén en simples pasos el seguro para tu bicicleta
                  </Typography> */}
                  {/* <Typography className={classes.labelSelect}>
                    Selecciona tu producto
                  </Typography>
                  <FormControl
                    style={{
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                    }}
                    variant="outlined"
                  >
                    <InputLabel
                      ref={(ref) => {
                        this.InputLabelRef = ref;
                      }}
                      id="select-producto-label"
                    >
                      Seleccione para continuar
                    </InputLabel>
                    <Select
                      value={this.state.producto}
                      onChange={(event) => this.handleChangeProducto(event)}
                      input={
                        <OutlinedInput labelWidth={this.state.labelWidth} />
                      }
                    >
                      {this.state.productosList.map((e) => (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  {/* <Typography className={classes.labelSelect}>
                  Ingresa el precio de tu bicicleta
                  </Typography> */}
                  <FormControl
                    style={{
                      marginBottom: "1rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                    }}
                    variant="outlined"
                    className={classes.homeInputForm}
                  >
                    <TextField
                      label="¿Cuál es el valor de tus bici?"
                      defaultValue={this.state["precio1"]}
                      onChange={(e) => this.handleChangeTextField(e, "precio1")}
                      variant="outlined"
                      type="number"
                      onKeyDown={(e) =>
                        (e.key == "," || e.key == ".") && e.preventDefault()
                      }
                    />
                  </FormControl>

                  <FormControl
                    className={classes.homeInputForm}
                    style={{
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                    }}
                    variant="outlined"
                  >
                    <TextField
                      label="Correo de Contacto"
                      defaultValue={this.state["Email"]}
                      onChange={(e) =>
                        this.handleChangeTextField(e, "Email")
                      }
                      variant="outlined"
                      type="email"
                    />
                  </FormControl>

                  <Button
                    onClick={() => this.toSecondTab()}
                    color="secondary"
                    className={classes.homeButtonForm}
                    variant="contained"
                    disabled={!this.state.precio1 || !this.state.introFormValid}
                    style={{
                      backgroundColor: this.currentMainColor()

                    }}
                  >
                    Iniciar Cotización
                  </Button>

                  <Typography 
                  className={classes.labelSelect}
                  style={{
                    fontSize: "15px",
                  }}
                  >
                  * Si tu bicicleta tiene menos de 6 meses puedes asegurarla
                  </Typography>

                </div>
              </Zoom>
            )}
            {this.state.activeTab === 2 && (
              <Zoom in={true} timeout={400}>
                <div className={classes.containerTab}>
                <Grid className={classes.progessBarTop} container>
                <Grid xs={6} sm={6} md={6}  ></Grid>
                <Grid xs={6} sm={6} md={6} className={classes.activeStep} ></Grid>
                </Grid>

                  <Button
                    onClick={() => this.toFirstTab()}
                    style={{
                      marginBottom: "1rem",
                      marginTop: "1rem",
                      color: "#878787",
                      fontFamily: "Roboto",
                    }}
                  >
                    <ArrowBack></ArrowBack> Paso 2 de 2 de la cotización
                  </Button>


                  <Typography className={classes.tituloPrincipal}>
                  Estas son las alternativas que tenemos para ti

                  </Typography>
                  <>

                  <div className={classes.conditionFeaturesList}>
                    <img  src={iconoFeaturesConditionShield} className={classes.conditionFeatureIconShield} alt="icono" style={{marginLeft:"20px"}}></img>
                    <img  src={iconoFeaturesConditionShield} className={classes.conditionFeatureIconShield} alt="icono"></img>
                    <img  src={iconoFeaturesConditionShield} className={classes.conditionFeatureIconShield} alt="icono"></img>
                    
                      <div className={`${classes.conditionFeatures}`}>
                        <img  src={iconoFeaturesCondition} className={classes.conditionFeatureIcon} alt="icono"></img>
                         <div className={classes.conditionFeatureText}> Cobertura de robo y pérdida total por daño</div>
                      </div>
                      <div className={`${classes.conditionFeatures}`}>
                        <img  src={iconoFeaturesCondition} className={classes.conditionFeatureIcon} alt="icono"></img>
                         <div className={classes.conditionFeatureText}> ¿Te encanta tu bici? Ahora quedará protegida</div>
                      </div>
                      <div className={`${classes.conditionFeatures}`}>
                        <img  src={iconoFeaturesCondition} className={classes.conditionFeatureIcon} alt="icono"></img>
                         <div className={classes.conditionFeatureText}> En caso de robo deducible del 30%
                         <small style={{fontWeight: "normal"}}> Ejemplo: $1,000.000 fue el costo de tu bici. El seguro pagará $700.000. </small></div>
                      </div>
                      <div className={`${classes.conditionFeatures}`}>
                        <img  src={iconoFeaturesCondition} className={classes.conditionFeatureIcon} alt="icono"></img>
                         <div className={classes.conditionFeatureText}> En caso de pérdida total por daño deducible del 15%</div>
                      </div>
                  
                      <div className={classes.conditionFeatureText} style={{color:"#116cc1", textAlign:"center", marginBottom: "10px", marginTop: "30px"}}> Selecciona la que más te acomoda</div>

                  <div style={{width: "100%"}}>
                    {this.state.planesEncontrados.map((e, i) => (
                      <div
                        onClick={() => this.seleccionarPlan(e)}
                        key={i}
                        className={`${classes.tarjetaPlan} ${
                          e.isActive ? "plan-active" : ""
                        }`}
                        // onMouseEnter={this.handleMouseEnterCard} // Or onMouseOver
                        // onMouseLeave={this.handleMouseLeaveCard}
                        style={{ borderColor:  e.isActive ? this.currentMainColor() : "" , backgroundColor: e.isActive ? this.currentMainColor() : "" }}
                      >
                        <Typography
                          style={{ marginBottom: "10px", color: "#354851" }}
                          className={classes.tituloPlan}
                        >
                          {e.condiciones}
                        </Typography>
                        {/* <Typography className={classes.textoPlan}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: e.consideraciones,
                            }}
                          />
                        </Typography> */}
                        <Typography className={classes.precioPlan} style={{ marginBottom: "0",  color: "#354851" }} >
                          ${parseFloat(e.pricePesos).toLocaleString("de-DE")} <span style={{ fontSize: "14px" }}>mensual</span>
                        </Typography>

                        {/* <Typography className={classes.precioPlan} style={{ marginBottom: "0" }} >
                          Valor: ${parseFloat(e.pricePesos).toLocaleString("de-DE")} <span style={{ fontSize: "14px" }}>mensual</span>
                        </Typography>
                        <Typography className={classes.precioPlanRef}>
                          ({parseFloat(e.priceUf).toLocaleString("de-DE")} UF)  
                          <small style={{ fontSize: "12px", fontStyle: 400, marginLeft: "5px" }}>(Referencia ${parseFloat(e.valueUf).toLocaleString("es")} al {e.dateUf})</small>
                        </Typography> */}


                        {/* <Typography className={classes.precioPlan}>
                          ${" "}
                          {parseFloat(
                            e.pricePesos
                          ).toLocaleString("de-DE")}{" "}
                          Pesos
                        </Typography> */}
                      </div>
                    ))}
                    </div>
                    </div>
                  </>

                  <Button
                    ref={this.myRef}
                    onClick={() => this.toForthTab()}
                    disabled={!this.state.planSeleccionado}
                    color="secondary"
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontWeight: "bold",
                      fontSize: "24px",
                      textTransform: "capitalize",
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      padding: "1rem 0rem",
                      backgroundColor: this.currentMainColor()
                    }}
                    variant="contained"
                  >
                    Activar mi protección
                  </Button>
                </div>
              </Zoom>
            )}
            {this.state.activeTab === 3 && (
              <Zoom in={true} timeout={400}>
                <div className={classes.containerTab}>
                  <Button
                    onClick={() => this.toSecondTab()}
                    style={{
                      marginBottom: "1rem",
                      marginTop: "2rem",
                      color: "#878787",
                      fontFamily: "Roboto",
                    }}
                  >
                    <ArrowBack></ArrowBack> Volver atrás
                  </Button>
                  <Typography className={classes.tituloPrincipal}>
                    {this.state.planSeleccionado.name}
                  </Typography>
                  <Typography className={classes.textoPlanDetalle}>
                    
                    <span  dangerouslySetInnerHTML={{
                        __html: this.state.planSeleccionado.condiciones,
                      }}
                    />
                  </Typography>
                  {/* <Typography className={classes.precioPlanDetalle}>
                    ${" "}
                    {parseFloat(
                      this.state.planSeleccionado.valor_clp_inseguro
                        ? this.state.planSeleccionado.valor_clp_inseguro
                        : this.state.planSeleccionado.formula
                    ).toLocaleString("de-DE")}{" "}
                    Pesos
                  </Typography> */}

                  <Typography className={classes.precioPlanDetalle} style={{ marginBottom: "0px", color: this.currentMainColor() }}>
                          Valor: ${parseFloat(this.state.planSeleccionado.pricePesos).toLocaleString("de-DE")} <span style={{ fontSize: "18px" }}>mensual</span>
                        </Typography>
                        <Typography className={classes.precioPlanRef} style={{marginBottom: "25px", color: this.currentMainColor()}}>
                          ({parseFloat(this.state.planSeleccionado.priceUf).toLocaleString("de-DE")} UF)
                          <small style={{ fontSize: "12px", fontStyle: 400, marginLeft: "5px" }}>(Referencia ${parseFloat(this.state.planSeleccionado.valueUf).toLocaleString("es")} al {this.state.planSeleccionado.dateUf})</small>
                  </Typography>
                  
                  {/* <Typography className={classes.textoPlan2Detalle}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.planSeleccionado.consideraciones,
                      }}
                    />
                  </Typography> */}
                  <Link
                    target="_blank"
                    href={
                      this.state.planSeleccionado.file_url
                        ? this.state.planSeleccionado.file_url
                        : pdfTerminosCondiciones
                    }
                    style={{ display: "flex" }}
                    className={classes.textoPlan2Detalle}
                  >
                    <CloudDownload
                      style={{ marginRight: "10px" }}
                    ></CloudDownload>{" "}
                    Descargar documento
                  </Link>
                  <Button
                    onClick={() => this.toForthTab()}
                    disabled={!this.state.planSeleccionado}
                    color="secondary"
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontWeight: "bold",
                      fontSize: "24px",
                      textTransform: "capitalize",
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                      padding: "1rem 0rem",
                      backgroundColor: this.currentMainColor()
                    }}
                    variant="contained"
                  >
                    Seleccionar
                  </Button>
                </div>
              </Zoom>
            )}
            {this.state.activeTab === 4 && (
              <Zoom in={true} timeout={400}>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                  <div className={classes.containerTab}>
                  <Grid className={classes.progessBarTop} container>
                  <Grid xs={6} sm={6} md={6} className={classes.activeStep}></Grid>
                  <Grid xs={6} sm={6} md={6}  ></Grid>
                  </Grid>
                  
                    <Button
                      onClick={() => this.toSecondTab()}
                      style={{
                        marginBottom: "1rem",
                        marginTop: "0rem",
                        color: "#878787",
                        fontFamily: "Roboto",
                      }}
                    >
                      <ArrowBack></ArrowBack> Estás en el paso 1 de 2 de la contratación
                    </Button>

              
                    <Typography className={classes.tituloPrincipal}>
                    Completa tu protección con estos datos
                    </Typography>
                    <Grid container className={classes.checkoutStep}>
                        <Grid item xs={2} sm={2} md={2}>
                          <img
                            src={require("../images/icons/002-lock.png")}
                            className={classes.checkoutStepIcon}
                            alt="icono"
                          ></img>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <Typography className={classes.checkoutStepText}>
                          Datos Personales
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["RUT"]}
                          onChange={(e) => this.handleChangeTextField(e, "RUT")}
                          label="RUT (sin puntos y con guión)"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Nombres"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Nombres")
                          }
                          label="Nombres"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Apellido Paterno"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Apellido Paterno")
                          }
                          label="Apellido Paterno"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Apellido Materno"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Apellido Materno")
                          }
                          label="Apellido Materno"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          defaultValue={this.state["Fecha de Nacimiento"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Fecha de Nacimiento")
                          }
                          label="Fecha de Nacimiento"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Dirección"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Dirección")
                          }
                          label="Dirección"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="select-region-label">
                            Región
                          </InputLabel>
                          <Select
                            label="Región"
                            id="select-region"
                            value={this.state["Región"]}
                            onChange={(e) => this.handleChangeRegion(e)}
                          >
                            {this.state.regionesList.map((e) => (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="select-comuna-label">
                            Comuna
                          </InputLabel>
                          <Select
                            label="Comuna"
                            id="select-comuna"
                            value={this.state["Comuna"]}
                            onChange={(e) =>
                              this.handleChangeTextField(e, "Comuna")
                            }
                          >
                            {this.state.comunasList.map((e) => (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.checkoutStep}>
                        <Grid item xs={2} sm={2} md={2}>
                          <img
                            src={require("../images/icons/008-user.png")}
                            className={classes.checkoutStepIcon}
                            alt="icono"
                          ></img>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <Typography className={classes.checkoutStepText}>
                          Datos de Contacto
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Teléfono móvil/fijo"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Teléfono móvil/fijo")
                          }
                          label="Teléfono móvil/fijo"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Email"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Email")
                          }
                          label="Email"
                          type="email"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    <Grid container className={classes.checkoutStep}>
                        <Grid item xs={2} sm={2} md={2}>
                          <img
                            src={require("../images/icons/001-shopping-cart.png")}
                            className={classes.checkoutStepIcon}
                            alt="icono"
                          ></img>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <Typography className={classes.checkoutStepText}>
                          Datos de la Compra
                        </Typography>
                      </Grid>
                    </Grid>


                    <Grid container spacing={0}>
                      {this.state.campos_formulario_venta.map((am, ind) => {
                        if (am.name == "N° de pedido") {
                          return (
                            <Grid
                              key={ind}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingRight: "1rem",
                                marginBottom: "1rem",
                              }}
                              item
                              xs={6}
                              sm={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                              md={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                            >
                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item>
                                  <Tooltip title="¿Cómo lo obtengo?">
                                    <IconButton
                                      onClick={() => this.handleClickOpen()}
                                      aria-label="¿Cómo lo obtengo?"
                                    >
                                      <HelpIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  style={{ flexGrow: "1", cursor: "pointer" }}
                                >
                                  <TextField
                                    placeholder="N° de pedido (R123456789) o N° de boleta (7-9 digitos)"
                                    style={{ width: "100%" }}
                                    type={
                                      am.data_type == "int" ? "number" : "text"
                                    }
                                    defaultValue={this.state[am.name]}
                                    onChange={(e) =>
                                      this.handleChangeTextField(e, am.name)
                                    }
                                    onKeyDown={(e) =>
                                      am.data_type == "int" &&
                                      (e.key == "," || e.key == ".") &&
                                      e.preventDefault()
                                    }
                                    label={"N° de pedido o N° de boleta"}
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        }
                        if (am.name == "Fecha de venta") {
                          return (
                            <Grid
                              key={ind}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingRight: "1rem",
                                marginBottom: "1rem",
                              }}
                              item
                              xs={6}
                              sm={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                              md={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                            >
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                type="date"
                                defaultValue={this.state[am.name]}
                                onChange={(e) =>
                                  this.handleChangeTextField(e, am.name)
                                }
                                label={am.name}
                                variant="outlined"
                              />
                            </Grid>
                          );
                        }
                        if (am.name == "N° encargo") {
                          return (
                            <Grid
                              key={ind}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingRight: "1rem",
                                marginBottom: "1rem",
                              }}
                              item
                              xs={6}
                              sm={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                              md={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                            >
                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item>
                                  <Tooltip title="¿Cómo lo obtengo?">
                                    <IconButton
                                      onClick={() => this.handleClickOpen()}
                                      aria-label="¿Cómo lo obtengo?"
                                    >
                                      <HelpIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  style={{ flexGrow: "1", cursor: "pointer" }}
                                >
                                  <TextField
                                    //placeholder="N° de pedido (R123456789) o N° de boleta (7-9 digitos)"
                                    style={{ width: "100%" }}
                                    type={
                                      am.data_type == "int" ? "number" : "text"
                                    }
                                    defaultValue={this.state[am.name]}
                                    onChange={(e) =>
                                      this.handleChangeTextField(e, am.name)
                                    }
                                    onKeyDown={(e) =>
                                      am.data_type == "int" &&
                                      (e.key == "," || e.key == ".") &&
                                      e.preventDefault()
                                    }
                                    label={"N° Pedido"}
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        }

                        if (am.name == "Valor del anteojo") {
                          this.state["Valor del anteojo"] = this.state.precio1;
                          return (
                            <Grid
                              key={ind}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingRight: "1rem",
                                marginBottom: "1rem",
                              }}
                              item
                              xs={6}
                              sm={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                              md={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                            >
                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Grid
                                  style={{ flexGrow: "1", cursor: "pointer" }}
                                >
                                  <TextField
                                    // placeholder="N° de pedido (R123456789) o N° de boleta (7-9 digitos)"
                                    style={{ width: "100%" }}
                                    type={
                                      am.data_type == "int" ? "number" : "text"
                                    }
                                    defaultValue={this.state.precio1}
                                    // value={this.state.precio1}
                                    onChange={(e) =>
                                      this.handleChangeTextField(e, am.name)
                                    }
                                    onKeyDown={(e) =>
                                      am.data_type == "int" &&
                                      (e.key == "," || e.key == ".") &&
                                      e.preventDefault()
                                    }
                                    label={am.name}
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        }


                        if (am.name == "El valor del equipo") {
                          this.state["Valor de la Bicicleta"] = this.state.precio1;
                          return (
                            <Grid
                              key={ind}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingRight: "1rem",
                                marginBottom: "1rem",
                              }}
                              item
                              xs={6}
                              sm={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                              md={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                            >
                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Grid
                                  style={{ flexGrow: "1", cursor: "pointer" }}
                                >
                                  <TextField
                                    // placeholder="N° de pedido (R123456789) o N° de boleta (7-9 digitos)"
                                    style={{ width: "100%" }}
                                    type={
                                      am.data_type == "int" ? "number" : "text"
                                    }
                                    defaultValue={this.state.precio1}
                                    // value={this.state.precio1}
                                    onChange={(e) =>
                                      this.handleChangeTextField(e, am.name)
                                    }
                                    onKeyDown={(e) =>
                                      am.data_type == "int" &&
                                      (e.key == "," || e.key == ".") &&
                                      e.preventDefault()
                                    }
                                    label="Valor de la Bicicleta"
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        }
                        if (am.name == "Fecha de compra") {
                          return (
                            <Grid
                              key={ind}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingRight: "1rem",
                                marginBottom: "1rem",
                              }}
                              item
                              xs={6}
                              sm={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                              md={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                            >
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                type="date"
                                defaultValue={this.state[am.name]}
                                onChange={(e) =>
                                  this.handleChangeTextField(e, am.name)
                                }
                                label={am.name}
                                variant="outlined"
                              />
                            </Grid>
                          );
                        }

                        // if (am.name == "Modelo") {
                        //   return (
                        //     <Grid
                        //       key={ind}
                        //       style={{
                        //         display: "flex",
                        //         flexDirection: "column",
                        //         paddingRight: "1rem",
                        //         marginBottom: "1rem",
                        //       }}
                        //       item
                        //       xs={6}
                        //       sm={
                        //         (ind + 1) % 2 === 1 &&
                        //         ind + 1 ==
                        //           this.state.campos_formulario_venta.length
                        //           ? 12
                        //           : 6
                        //       }
                        //       md={
                        //         (ind + 1) % 2 === 1 &&
                        //         ind + 1 ==
                        //           this.state.campos_formulario_venta.length
                        //           ? 12
                        //           : 6
                        //       }
                        //     >
                        //       <Grid
                        //         style={{
                        //           display: "flex",
                        //           flexDirection: "row",
                        //           alignItems: "center",
                        //         }}
                        //       >
                        //         <Grid
                        //           style={{ flexGrow: "1", cursor: "pointer" }}
                        //         >
                        //           <TextField
                        //             // placeholder="N° de pedido (R123456789) o N° de boleta (7-9 digitos)"
                        //             style={{ width: "100%" }}
                        //             type={
                        //               am.data_type == "int" ? "number" : "text"
                        //             }
                        //             defaultValue={this.state[am.name]}
                        //             // value={this.state.precio1}
                        //             onChange={(e) =>
                        //               this.handleChangeTextField(e, am.name)
                        //             }
                        //             onKeyDown={(e) =>
                        //               am.data_type == "int" &&
                        //               (e.key == "," || e.key == ".") &&
                        //               e.preventDefault()
                        //             }
                        //             label="Marca/Modelo"
                        //             variant="outlined"
                        //           />
                        //         </Grid>
                        //       </Grid>
                        //     </Grid>
                        //   );
                        // }
                        if (am.name == "N° de boleta") {
                          return ("");

                        }
                        if (am.name == "N° de serie") {
                          return ("");
                          return (
                            <Grid
                              key={ind}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingRight: "1rem",
                                marginBottom: "1rem",
                              }}
                              item
                              xs={12}
                              sm={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                              md={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                            >
                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item>
                                  <Tooltip title="¿Cómo lo obtengo?">
                                    <IconButton
                                      onClick={() => this.handleClickOpen()}
                                      aria-label="¿Cómo lo obtengo?"
                                    >
                                      <HelpIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  style={{ flexGrow: "1", cursor: "pointer" }}
                                >
                                  <TextField
                                    // placeholder="{{am.name}}"
                                    style={{ width: "100%" }}
                                    type={
                                      am.data_type == "int" ? "number" : "text"
                                    }
                                    defaultValue={this.state[am.name]}
                                    onChange={(e) =>
                                      this.handleChangeTextField(e, am.name)
                                    }
                                    onKeyDown={(e) =>
                                      am.data_type == "int" &&
                                      (e.key == "," || e.key == ".") &&
                                      e.preventDefault()
                                    }
                                    label={am.name}
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        }

                        if (am.name == "Tipo de bicicleta") {
                          return (
                            <Grid
                            key={ind}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingRight: "1rem",
                              marginBottom: "1rem",
                            }}
                            item
                              xs={6}
                              sm={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                              md={
                                (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                          >
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel id="select-bicitype-label">
                                Tipo de bicicleta
                              </InputLabel>
                              <Select
                                label={am.name}
                                id="select-bicitype"
                                value={this.state[am.name]}
                                onChange={(e) => this.handleChangeSelectField(e, am.name)}
                              >
                                {this.state.biciTypes.map((e) => (
                                  <MenuItem key={e} value={e}>
                                    {e}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          )

                        }

                        return (
                          <Grid
                            key={ind}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingRight: "1rem",
                              marginBottom: "1rem",
                            }}
                            item
                            xs={6}
                            sm={
                              (ind + 1) % 2 === 1 &&
                              ind + 1 ==
                                this.state.campos_formulario_venta.length
                                ? 12
                                : 6
                            }
                            md={
                              (ind + 1) % 2 === 1 &&
                              ind + 1 ==
                                this.state.campos_formulario_venta.length
                                ? 12
                                : 6
                            }
                          >
                            <TextField
                              type={am.data_type == "int" ? "number" : "text"}
                              defaultValue={this.state[am.name]}
                              onChange={(e) =>
                                this.handleChangeTextField(e, am.name)
                              }
                              onKeyDown={(e) =>
                                am.data_type == "int" &&
                                (e.key == "," || e.key == ".") &&
                                e.preventDefault()
                              }
                              label={am.name}
                              variant="outlined"
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                    




                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          // display: "flex",
                          // flexDirection: "column",
                          // paddingRight: "5px",
                          // marginBottom: "1rem",
                          // border: "1px dashed #9e9e9e",
                          // marginRight: "5px",
                          // paddingLeft: "5px",
                          // paddingTop: "5px",
                          // paddingBottom: "10px",
                        }}
                        className={classes.imagesFormBlocks}
                        item
                        xs={6}
                        sm={4}
                        md={4}
                      >
                        <Tooltip title="Imagen" placement="top">
                        <label
                        style={{
                          fontSize: "11px",
                          marginBottom: "5px",
                        }}
                        >Foto Frontal</label>
                        </Tooltip>
                        {!this.state.picture1 &&
                        <Button variant="contained" component="label" size="small" color="secondary" style={{backgroundColor: this.currentMainColor()}}>
                          Subir foto
                          <input hidden accept="image/*" multiple type="file" 
                          onChange={(e) =>
                            this.handleUploadImage( e.target.files[0], 'picture1')
                          }
                        />
                        </Button>
                        }
                        <Grid container spacing={0} align="center" direction="row">
                           
                        {this.state.picture1 &&
                          <Grid item xs={6} >
                           <IconButton color="primary" onClick={() => this.handleopenDialogPreviewImage(this.state.picture1)}>
                           <Pageview />
                         </IconButton>
                         </Grid>
                        }
                        {this.state.picture1 &&
                          <Grid item xs={6} >
                           <IconButton color="secondary" onClick={() => this.handleoRemoveImage('picture1')}>
                           <Delete />
                         </IconButton>
                         </Grid>
                        }
                        </Grid>
                      </Grid>

                      {/* Picture 2 */}
                      {/* <Grid
                         style={{
                        }}
                        className={classes.imagesFormBlocks}
                        item
                        xs={6}
                        sm={4}
                        md={4}
                      >
                        <Tooltip title="Imagen" placement="top">
                        <label
                        style={{
                          fontSize: "11px",
                          marginBottom: "5px",
                        }}
                        >Foto Lateral Derecho</label>
                        </Tooltip>
                        {!this.state.picture2 &&
                        <Button variant="contained" component="label" size="small" color="secondary" style={{backgroundColor: this.currentMainColor()}}>
                          Subir foto
                          <input hidden accept="image/*" multiple type="file" 
                          onChange={(e) =>
                            this.handleUploadImage( e.target.files[0], 'picture2')
                          }
                        />
                        </Button>
                        }
                        <Grid container spacing={0} align="center" direction="row">
                           
                           {this.state.picture2 &&
                             <Grid item xs={6} >
                              <IconButton color="primary" onClick={() => this.handleopenDialogPreviewImage(this.state.picture2)}>
                              <Pageview />
                            </IconButton>
                            </Grid>
                           }
                           {this.state.picture2 &&
                             <Grid item xs={6} >
                              <IconButton color="secondary" onClick={() => this.handleoRemoveImage('picture2')}>
                              <Delete />
                            </IconButton>
                            </Grid>
                           }
                           </Grid>
                      </Grid> */}

                       {/* Picture 3 */}
                       {/* <Grid
                        style={{
                        }}
                        className={classes.imagesFormBlocks}
                        item
                        xs={6}
                        sm={4}
                        md={4}
                      >
                        <Tooltip title="Imagen" placement="top">
                        <label
                        style={{
                          fontSize: "11px",
                          marginBottom: "5px",
                        }}
                        >Foto Lateral Izquierdo</label>
                        </Tooltip>
                        {!this.state.picture3 &&
                        <Button variant="contained" component="label" size="small" color="secondary" style={{backgroundColor: this.currentMainColor()}}>
                          Subir foto
                          <input hidden accept="image/*" multiple type="file" 
                          onChange={(e) =>
                            this.handleUploadImage( e.target.files[0], 'picture3')
                          }
                        />
                        </Button>
                        }
                       <Grid container spacing={0} align="center" direction="row">
                           
                           {this.state.picture3 &&
                             <Grid item xs={6} >
                              <IconButton color="primary" onClick={() => this.handleopenDialogPreviewImage(this.state.picture3)}>
                              <Pageview />
                            </IconButton>
                            </Grid>
                           }
                           {this.state.picture3 &&
                             <Grid item xs={6} >
                              <IconButton color="secondary" onClick={() => this.handleoRemoveImage('picture3')}>
                              <Delete />
                            </IconButton>
                            </Grid>
                           }
                           </Grid>
                      </Grid> */}

                      {/* Picture 4 */}
                      <Grid
                        style={{
                          //  display: "flex",
                          // flexDirection: "column",
                          // paddingRight: "5px",
                          // marginBottom: "1rem",
                          // border: "1px dashed #9e9e9e",
                          // marginRight: "15px",
                          // paddingLeft: "5px",
                          // paddingTop: "5px",
                          // paddingBottom: "10px",
                        }}
                        className={classes.imagesFormBlocks}
                        item
                        xs={6}
                        sm={4}
                        md={4}
                      >
                        <Tooltip title="Imagen" placement="top">
                        <label
                        style={{
                          fontSize: "11px",
                          marginBottom: "5px",
                        }}
                        >Foto Número de Serie</label>
                        </Tooltip>
                        {!this.state.picture4 &&
                        <Button variant="contained" component="label" size="small" color="secondary" style={{backgroundColor: this.currentMainColor()}}>
                          Subir foto
                          <input hidden accept="image/*" multiple type="file" 
                          onChange={(e) =>
                            this.handleUploadImage( e.target.files[0], 'picture4')
                          }
                        />
                        </Button>
                        }
                       <Grid container spacing={0} align="center" direction="row">
                           
                           {this.state.picture4 &&
                             <Grid item xs={6} >
                              <IconButton color="primary" onClick={() => this.handleopenDialogPreviewImage(this.state.picture4)}>
                              <Pageview />
                            </IconButton>
                            </Grid>
                           }
                           {this.state.picture4 &&
                             <Grid item xs={6} >
                              <IconButton color="secondary" onClick={() => this.handleoRemoveImage('picture4')}>
                              <Delete />
                            </IconButton>
                            </Grid>
                           }
                           </Grid>
                      </Grid>



                      {/* Picture 5 */}
                      <Grid
                        style={{
                          //  display: "flex",
                          // flexDirection: "column",
                          // paddingRight: "5px",
                          // marginBottom: "1rem",
                          // border: "1px dashed #9e9e9e",
                          // marginRight: "15px",
                          // paddingLeft: "5px",
                          // paddingTop: "5px",
                          // paddingBottom: "10px",
                        }}
                        className={classes.imagesFormBlocks}
                        item
                        xs={6}
                        sm={4}
                        md={4}
                      >
                        <Tooltip title="Imagen" placement="top">
                        <label
                        style={{
                          fontSize: "11px",
                          marginBottom: "5px",
                        }}
                        >Boleta de Compra</label>
                        </Tooltip>
                        {!this.state.picture5 &&
                        <Button variant="contained" component="label" size="small" color="secondary" style={{backgroundColor: this.currentMainColor()}}>
                          Subir foto
                          <input hidden accept="image/*" multiple type="file" 
                          onChange={(e) =>
                            this.handleUploadImage( e.target.files[0], 'picture5')
                          }
                        />
                        </Button>
                        }
                       <Grid container spacing={0} align="center" direction="row">
                           
                           {this.state.picture5 &&
                             <Grid item xs={6} >
                              <IconButton color="primary" onClick={() => this.handleopenDialogPreviewImage(this.state.picture5)}>
                              <Pageview />
                            </IconButton>
                            </Grid>
                           }
                           {this.state.picture5 &&
                             <Grid item xs={6} >
                              <IconButton color="secondary" onClick={() => this.handleoRemoveImage('picture5')}>
                              <Delete />
                            </IconButton>
                            </Grid>
                           }
                           </Grid>
                      </Grid>
                      
         
                    </Grid>


                    <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion1}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion1")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{ paddingTop: "10px", marginBottom: "1rem" }}
                      >
                        <Link
                          style={{
                            textDecoration: "underline",
                            fontFamily: "Source Sans Pro",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: this.currentMainColor(),
                          }}
                          href={pdfTerminosCondiciones}
                          target="_blank"
                        >
                          He leído y comprendo las condiciones generals y particulares, autorizo el cobro de la primera de este Seguro y las Comunicaciones relacionadas a este, Tambien autorizo que BCI Seguros Generales S.A. para almacenar, tartar mis datos personales{" "}
                        </Link>
                      </Grid>
                    </Grid>

                    {/* <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion2}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion2")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingTop: "10px",
                          marginBottom: "1rem",
                          color: "#878787",
                          fontFamily: "Source Sans Pro",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Autorizo que el cobro de la prima de este seguro se
                        realice en la tarjeta de crédito indicada y que las
                        comunicaciones relacionadas al seguro se envíen al
                        correo electrónico informado. En caso de desconocerse el
                        correo electrónico o no ser efectivo el envío, autorizo
                        a que las comunicaciones me sean enviadas al correo
                        electrónico que he informado al contratante de la póliza
                        colectiva
                      </Grid>
                    </Grid> */}
                    {/* <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion3}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion3")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingTop: "10px",
                          marginBottom: "1rem",
                          color: "#878787",
                          fontFamily: "Source Sans Pro",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Autorizo a BCI Seguros Generales. para que pueda
                        efectuar el tratamiento y almacenamiento de mis datos
                        personales proporcionados en esta contratación del
                        Seguro y puedan ser utilizados en la administración,
                        análisis, cumplimiento y/o auditoría del mismo.
                      </Grid>
                    </Grid> */}
                    
                    {/* <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion5}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion5")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingTop: "10px",
                          marginBottom: "1rem",
                          color: "#878787",
                          fontFamily: "Source Sans Pro",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Declaro que la bicicleta es para uso particular y que soy el propietario de la  bicicleta a asegurar.
                      </Grid>
                    </Grid> */}


                    {/* <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion4}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion4")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingTop: "10px",
                          marginBottom: "1rem",
                          color: "#878787",
                          fontFamily: "Source Sans Pro",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Declaro que acepto voluntariamente la contratación de
                        este seguro.
                      </Grid>
                    </Grid> */}
                   
                    <Button
                      type="submit"
                      disabled={!formValid}
                      color="secondary"
                      style={{
                        display: "none",
                        fontFamily: "Source Sans Pro",
                        fontWeight: "bold",
                        fontSize: "24px",
                        textTransform: "capitalize",
                        marginBottom: "2rem",
                        marginTop: "2rem",
                        alignSelf: "stretch",
                        maxWidth: "450px",
                        padding: "1rem 0rem",
                      }}
                      variant="contained"
                    >
                      Iniciar Pago
                    </Button>
                    <Button
                      disabled={this.state.isLoading || !formValid || this.state.formInputError}
                      onClick={() => this.handleClickBotonPagarMercadopago()}
                      type="button"
                      color="secondary"
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontWeight: "bold",
                        fontSize: "24px",
                        textTransform: "capitalize",
                        marginBottom: "2rem",
                        marginTop: "2rem",
                        alignSelf: "stretch",
                        maxWidth: "450px",
                        padding: "1rem 0rem",
                        backgroundColor: this.currentMainColor()
                      }}
                      variant="contained"
                    >
                      Iniciar el Pago
                    </Button>
                  </div>
                </form>
              </Zoom>
            )}
          </Grid>
          <Grid className={classes.fondoImagen} item xs={12} sm={12} md={6}>
            <img
              className={classes.iconoCentral}
              src={this.currentIconoCentral()}
              alt="icono"
            ></img>
          </Grid>
        </Grid>
        <FooterGarnet />

        {this.state.mensajesError.map((e, i) => (
          <Snackbar
            style={{ marginBottom: String(55 * i) + "px" }}
            key={i}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={e.active}
            autoHideDuration={6000}
            variant="error"
            message={e.message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => this.handleCloseSnackbar(i)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        ))}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  preference_id: state.preference_id,
});
export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(Landing1))
);
